import { useCallback, useEffect, useState } from "react";
import { Configuration, OpenAIApi } from "openai";
import "./App.css";

function App() {
  const [data, setData] = useState();
  async function inputHandle() {
    const message = document.querySelector(".input").value;
    setData("Loading. . .");
    // const apiUrl = "https://api.openai.com/v1/chat/completions";
    const apiKey = "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";
    const configuration = new Configuration({
      apiKey: apiKey,
    });
    const openai = new OpenAIApi(configuration);
    const completion = await openai.createChatCompletion({
      model: "gpt-3.5-turbo",
      messages: [{ role: "user", content: message }],
    });
    setData(completion.data.choices[0].message.content);
  }

  const handler = useCallback((event) => {
    if (event.key === "Enter") {
      document.querySelector(".ask").click();
    }
  }, []);

  useEffect(() => {
    window.addEventListener("keydown", handler);
    return () => {
      window ?? window.removeEventListener("keydown", handler);
    };
  }, [handler]);

  return (
    <main className="w-full max-w-screen-2xl px-8">
      <h1 className="text-4xl font-bold mt-16">
        How to handle refund requests from customers to maintain customer trust
      </h1>
      <h3 className="text-3xl font-medum mt-12">
        1. Make sure you have a clear refund policy
      </h3>
      <p className="text-xl mt-6">
        Clearly define your refund policy and make sure it’s easily accessible
        on your website (like in your customer portal). The policy should
        include all the conditions, deadlines, and acceptable reasons for a
        product return. This way you can always point customers back to your
        policy whenever someone requests a refund. Otherwise, you lack the
        authority to decline refunds in the first place.
      </p>
      <h3 className="text-3xl font-medum mt-12">
        2. Respond to refund requests quickly
      </h3>
      <p className="text-xl mt-6">
        When you get a refund request, act as quickly as possible. Since the
        customer is already unhappy with their experience, waiting for your
        response for too long might only frustrate them more. If you’re not sure
        how to proceed, send them a cancellation confirmation message to show
        your acknowledgment of the issue. Let them know you will get back to
        them as soon as possible.
      </p>
      <h3 className="text-3xl font-medum mt-12">
        3. Keep your cool and stay professional
      </h3>
      <p className="text-xl mt-6">
        It’s very easy to become annoyed, frustrated, and defensive when someone
        speaks negatively about your product or service and demands their money
        back. None of these emotions will help you resolve the situation and
        ensure customer satisfaction. Take some time to carefully consider their
        request and make sure you are responding in a non-confrontational way.
      </p>
      <h3 className="text-3xl font-medum mt-12">4. Explain your decision</h3>
      <p className="text-xl mt-6">
        Once you’ve decided how you’ll respond to a customer’s refund request,
        inform the customer, justify your decision and explain the next steps
        you are going to take. If you will refund the customer’s money, tell
        them how and when they’ll receive the refund. If you reject their
        request, provide an explanation of why it didn’t fall within your
        company’s refund policy, and send them a nice rejection letter.
      </p>
      <h3 className="text-3xl font-medum mt-12">
        5. Offer an alternative solution
      </h3>
      <p className="text-xl mt-6">
        In many cases, you may be able to handle a customer’s refund request in
        a different way, without actually refunding their money. If you can get
        a better understanding of the customer’s needs and requirements, you
        might be able to adjust your offering or propose another solution that
        might satisfy your customer.
      </p>
      <h3 className="text-3xl font-medum mt-12">6. Ask for some feedback</h3>
      <p className="text-xl mt-6">
        A refund request is always an opportunity to get customer feedback. If
        the reason for a refund is not clear, ask some follow-up questions to
        find out what they hoped to experience from your product or service and
        how it failed to meet their expectations. Though it’s not positive
        feedback, it could give you some valuable insights as to what you can
        possibly improve to satisfy future customers.
      </p>
      <pre
        style={{ whiteSpace: "pre-wrap", border: "1px solid grey" }}
        className="p-4 mt-4 rounded"
      >
        Hi [Name],
        <br />
        <br />
        Thanks for reaching out. Satisfying our customers is very important to
        us and I’m sorry our [product/ service] didn’t meet your expectations. I
        fully respect your decision and can only apologize for any problems your
        business experienced.
        <br />
        <br />
        We’ve processed your refund, and you should expect to see the amount
        credited to your account in about 3 to 5 business days.
        <br />
        <br />
        If you have any other questions or concerns, just reply to this email,
        I’ll be here to help you in any way I can.
        <br />
        <br />
        Best, [YOUR SIGNATURE]
      </pre>
      <div className="w-full h-[40rem] py-8 flex flex-col items-center  gap-4">
        <input
          type="text"
          className="input w-full h-12 rounded focus:brightness-110 px-4 text-lg shrink-0"
          style={{ outline: "none" }}
          placeholder="Ask something . . ."
        />
        <div className="flex gap-2">
          <button
            className="ask w-max py-2 px-12 bg-[#202020] hover:brightness-110 rounded shrink-0"
            onClick={inputHandle}
          >
            Ask
          </button>
        </div>
        <div
          className="w-full h-full rounded relative flex flex-col p-4 overflow-auto"
          style={{ border: "1px solid grey" }}
        >
          <button
            className="copy py-1 px-3 w-max h-max absolute right-2 top-2 rounded hover:bg-[#202020] text-sm"
            style={{ border: "1px solid grey" }}
            onClick={() => {
              navigator.clipboard.writeText(data);
            }}
          >
            Copy
          </button>
          <pre style={{ whiteSpace: "pre-wrap" }}>{data}</pre>
        </div>
      </div>
    </main>
  );
}

export default App;
